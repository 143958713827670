<template>
<div>
  <router-view />
</div>
</template>

<script>
export default {
  name: "SystemLayout",
}
</script>

<style scoped>

</style>